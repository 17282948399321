::-moz-selection {
  background-color: #6cbb23;
  color: #fff;
}
::selection {
  background-color: #6cbb23;
  color: #fff;
}
::-webkit-input-placeholder {
  color: #000;
  font-weight: 300;
}
:-moz-placeholder {
  color: #000;
  opacity: 1;
  font-weight: 300;
}
::-moz-placeholder {
  color: #000;
  opacity: 1;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #000;
  font-weight: 300;
}
::-ms-input-placeholder {
  color: #000;
  font-weight: 300;
}
body {
  color: #777;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
  position: relative;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
select {
  display: block;
}
figure {
  margin: 0;
}
a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
iframe {
  border: 0;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
  color: #777;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}
.card-panel {
  margin: 0;
  padding: 60px;
}
.btn i,
.btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1em;
  line-height: inherit;
}
.gray-bg {
  background: #f9f9ff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #222;
  line-height: 1.5em;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #222;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
  color: #222;
}
td,
th {
  border-radius: 0px;
}
.clear::before,
.clear::after {
  content: " ";
  display: table;
}
.clear::after {
  clear: both;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.fz-14 {
  font-size: 14px;
}
.fz-15 {
  font-size: 15px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-30 {
  font-size: 30px;
}
.fz-48 {
  font-size: 48px !important;
}
.fw100 {
  font-weight: 100;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500;
}
.f700 {
  font-weight: 700;
}
.fsi {
  font-style: italic;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-150 {
  margin-top: 150px;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-0-i {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-150 {
  padding-top: 150px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.p-40 {
  padding: 40px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-italic {
  font-style: italic;
}
.text-white {
  color: #fff;
}
.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.section-full {
  padding: 100px 0;
}
.section-half {
  padding: 75px 0;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
.flex-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.display-table {
  display: table;
}
.light {
  color: #fff;
}
.dark {
  color: #000;
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.container.fullwidth {
  width: 100%;
}
.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-padding {
  padding: 0;
}
.section-bg {
  background: #f9fafc;
}
@media (max-width: 767px) {
  .no-flex-xs {
    display: block !important;
  }
}
.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}
b,
i,
sup,
sub,
u,
del {
  color: #6cbb23;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5em;
}
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #777;
}
.button-area {
  background: #fff;
}
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.genric-btn:focus {
  outline: none;
}
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}
.genric-btn.large {
  line-height: 45px;
}
.genric-btn.medium {
  line-height: 30px;
}
.genric-btn.small {
  line-height: 25px;
}
.genric-btn.radius {
  border-radius: 3px;
}
.genric-btn.circle {
  border-radius: 20px;
}
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.genric-btn.arrow span {
  margin-left: 10px;
}
.genric-btn.default {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border:hover {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.primary {
  color: #fff;
  background: #6cbb23;
  border: 1px solid transparent;
}
.genric-btn.primary:hover {
  color: #6cbb23;
  border: 1px solid #6cbb23;
  background: #fff;
}
.genric-btn.rocket {
  color: #fff;
  background: #13525b;
  border: 1px solid transparent;
}
.genric-btn.rocket:hover {
  color: #13525b;
  border: 1px solid #13525b;
  background: #fff;
}
.genric-btn.strawberry {
  color: #fff;
  background: #d14a3b;
  border: 1px solid transparent;
}
.genric-btn.strawberry:hover {
  color: #d14a3b;
  border: 1px solid #d14a3b;
  background: #fff;
}
.genric-btn.primary-border {
  color: #6cbb23;
  border: 1px solid #6cbb23;
  background: #fff;
}
.genric-btn.primary-border:hover {
  color: #fff;
  background: #6cbb23;
  border: 1px solid transparent;
}
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.link {
  color: #222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}
.genric-btn.link:hover {
  color: #222;
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.link-border {
  color: #222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}
.genric-btn.link-border:hover {
  color: #222;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.disable {
  color: #222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #6cbb23;
}
.progress-table-wrap {
  overflow-x: scroll;
}
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}
.progress-table .country {
  width: 28.07%;
}
.progress-table .visit {
  width: 19.74%;
}
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}
.progress-table .table-head {
  display: flex;
}
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}
.progress-table .table-row .country img {
  margin-right: 15px;
}
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}
.list-style {
  width: 14px;
  height: 14px;
}
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #6cbb23;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}
.ordered-list {
  margin-left: 30px;
}
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #6cbb23;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list li span {
  font-weight: 300;
  color: #777;
}
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #6cbb23;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-alpha li span {
  font-weight: 300;
  color: #777;
}
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #6cbb23;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-roman li span {
  font-weight: 300;
  color: #777;
}
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}
.single-input:focus {
  outline: none;
}
.input-group-icon {
  position: relative;
}
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}
.input-group-icon .icon i {
  color: #797979;
}
.input-group-icon .single-input {
  padding-left: 45px;
}
.single-textarea {
  display: block;
  color: #000;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}
.single-textarea:focus {
  outline: none;
}
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}
.single-input-primary:focus {
  outline: none;
  border: 1px solid #6cbb23;
}
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #6cbb23;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.default-switch input:checked + label {
  left: 19px;
}
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.primary-switch input:checked + label:after {
  left: 19px;
}
.primary-switch input:checked + label:before {
  background: #6cbb23;
}
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.confirm-switch input:checked + label:after {
  left: 19px;
}
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-checkbox input:checked + label {
  background: url(./img/elements/primary-check.png) no-repeat center
    center/cover;
  border: none;
}
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-checkbox input:checked + label {
  background: url(./img/elements/success-check.png) no-repeat center
    center/cover;
  border: none;
}
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-checkbox input:checked + label {
  background: url(./img/elements/disabled-check.png) no-repeat center
    center/cover;
  border: none;
}
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-radio input:checked + label {
  background: url(./img/elements/primary-radio.png) no-repeat center
    center/cover;
  border: none;
}
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-radio input:checked + label {
  background: url(./img/elements/success-radio.png) no-repeat center
    center/cover;
  border: none;
}
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-radio input:checked + label {
  background: url(./img/elements/disabled-radio.png) no-repeat center
    center/cover;
  border: none;
}
.default-select {
  height: 40px;
}
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}
.default-select .nice-select .list .option.selected {
  color: #6cbb23;
  background: transparent;
}
.default-select .nice-select .list .option:hover {
  color: #6cbb23;
  background: transparent;
}
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.default-select .nice-select::after {
  right: 20px;
}
.form-select {
  height: 40px;
  width: 100%;
}
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}
.form-select .nice-select .list .option.selected {
  color: #6cbb23;
  background: transparent;
}
.form-select .nice-select .list .option:hover {
  color: #6cbb23;
  background: transparent;
}
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.form-select .nice-select::after {
  right: 20px;
}
body {
  position: relative;
}
.default-header {
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.menu-bar {
  cursor: pointer;
}
.menu-bar span {
  color: #000;
  font-size: 24px;
}
.menu-bar a {
  color: #6cbb23;
}
@media (max-width: 767px) {
  .main-menubar a {
    color: #fff;
  }
  .main-menubar a:hover {
    color: #000;
  }
}
.main-menubar {
  position: relative;
}
nav {
  margin-right: 70px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  transform-origin: 100% 50%;
}
@media (max-width: 767px) {
  nav {
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 47px;
    text-align: right;
    padding: 20px 0;
    z-index: 5;
    background: #6cbb23;
  }
}
nav.hide {
  transform: scale(0);
}
nav a {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  margin: 0 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  nav a {
    margin: 5px 25px;
  }
}
nav a:hover {
  color: #6cbb23;
}
body {
  background: #f6f6fc;
}
.oz-body-wrap {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
  margin: 50px;
  position: relative;
}
@media (max-width: 767px) {
  .oz-body-wrap {
    margin: 30px;
  }
}
@media (max-width: 575px) {
  .oz-body-wrap {
    margin: 10px;
  }
}
.section-gap {
  padding: 100px 0;
}
.section-title {
  padding-bottom: 30px;
}
.section-title h2 {
  margin-bottom: 20px;
}
.section-title p {
  font-size: 16px;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .section-title p br {
    display: none;
  }
}
.primary-btn {
  line-height: 40px;
  padding: 0 30px;
  border-radius: 20px;
  background: transparent;
  border: 1px solid #fff;
  color: #222;
  display: inline-block;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: uppercase;
}
.primary-btn:focus {
  outline: none;
}
.primary-btn span {
  color: #fff;
  position: relative;
  z-index: 2;
}
.primary-btn .mr-10 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.primary-btn:hover .mr-10 {
  margin-right: 20px;
}
.primary-btn.white-bg {
  background: #fff;
  border: 1px solid #eee;
  color: #6cbb23;
}
.primary-btn.white-bg span {
  color: #6cbb23;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.primary-btn.white-bg:hover {
  background: #6cbb23;
  color: #fff;
  border: 1px solid transparent;
}
.primary-btn.white-bg:hover span {
  color: #fff;
}
.banner-area {
  background: #fff;
}
@media (max-width: 767px) {
  .banner-area .fullscreen {
    height: 1000px !important;
  }
}
@media (max-width: 1199px) {
  .banner-left {
    margin-top: 60px;
  }
}
.story-content h1 {
  font-size: 48px;
  font-weight: 100;
  line-height: 1.2em;
  margin-bottom: 20px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .story-content h1 {
    font-size: 40px;
  }
}
@media (max-width: 991px) {
  .story-content h1 br {
    display: none;
  }
}
.story-content h6 {
  font-weight: 400;
}
.story-content p {
  margin-bottom: 60px;
  font-size: 16px;
}
@media (max-width: 991px) {
  .story-content p br {
    display: none;
  }
}
.story-content .sp-1 {
  font-weight: 600;
}
.story-content .sp-2 {
  color: #6cbb23;
  font-weight: 600;
}
.story-content .sp-3 {
  color: #13525b;
  font-weight: 600;
}
.story-content .sp-4 {
  color: #d14a3b;
  font-weight: 600;
}
@media (max-width: 768px) {
  .story-content {
    text-align: center;
  }
}
.generic-banner {
  background: #6cbb23;
}
.generic-banner .height {
  height: 600px;
}
@media (max-width: 767px) {
  .generic-banner .height {
    height: 400px;
  }
}
.generic-banner .banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .generic-banner .banner-content h2 br {
    display: none;
  }
}
.generic-banner .banner-content p {
  font-size: 16px;
}
@media (max-width: 991px) {
  .generic-banner .banner-content p br {
    display: none;
  }
}
.video-area {
  padding: 200px 0 60px 0;
  position: relative;
  background: url(./img/video-bg.jpg) no-repeat center center/cover;
}
.video-area .overlay-bg {
  background: rgba(108, 187, 35, 0.85);
}
.video-area .video-content {
  text-align: center;
  position: relative;
  z-index: 2;
}
.video-area .video-content a {
  display: inline-block;
  margin-bottom: 20px;
}
.video-area .video-content h3 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
.video-area .video-content h4 {
  font-weight: 300;
  font-size: 14px;
}
.video-area .video-content .video-desc {
  margin-top: 120px;
  text-align: right;
  align-self: flex-end;
}
.about-area {
  position: relative;
  background: white;
}
.about-area .story-content h2 {
  font-size: 36px;
  line-height: 1.25em;
  font-weight: 100;
}
.about-area .story-content h2 span {
  font-weight: 600;
}
.about-area .story-content p {
  line-height: 1.65em;
  font-size: 14px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .story-content {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.feature-area {
  background: url(./img/video-bg.jpg) no-repeat center center/cover;
}
.feature-area .overlay-bg {
  background: rgba(108, 187, 35, 0.85);
}
.feature-area .icon {
  font-size: 100px;
  color: #fff;
  background: #f0f8e9;
  text-align: center;
  padding: 50px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.feature-area .icon:hover {
  color: #fff;
  background-color: #6cbb23;
}
.feature-area .icon:hover .lnr {
  color: #fff;
}
.feature-area .lnr {
  color: #6cbb23;
}
.feature-area .desc {
  margin-top: 30px;
}
.feature-area .desc h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 2.5px;
}
.feature-area .desc p {
  color: #555;
}
.single-feature {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 30px;
}
.faq-area {
  background: #fff;
}
.counter-left {
  text-align: right;
}
.counter-left h2 {
  font-size: 48px;
  font-weight: 100;
  color: #6cbb23;
}
.counter-left p {
  font-size: 14px;
  font-weight: 300;
}
.counter-left .single-facts {
  margin-bottom: 26px;
}
@media (max-width: 768px) {
  .counter-left {
    text-align: center;
  }
}
.faq-content {
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 1199px) {
  .faq-content {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media (max-width: 767px) {
  .faq-content {
    text-align: center;
  }
}
.single-faq {
  margin-bottom: 30px;
}
.single-faq h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.single-faq p {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.contact-area {
  background: url(./img/contact-bg.jpg) no-repeat center center/cover;
  color: #fff;
}
.contact-area .overlay-bg {
  background: rgba(108, 187, 35, 0.85);
}
.contact-area h2 {
  font-size: 36px;
  font-weight: 100;
}
.contact-area h2 span {
  font-weight: 600;
}
.contact-area p {
  font-size: 14px;
  font-weight: 300;
}
.contact-form ::-webkit-input-placeholder {
  color: #fff;
  font-weight: 300;
}
.contact-form :-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-weight: 300;
}
.contact-form ::-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-weight: 300;
}
.contact-form :-ms-input-placeholder {
  color: #fff;
  font-weight: 300;
}
.contact-form ::-ms-input-placeholder {
  color: #fff;
  font-weight: 300;
}
.contact-form .alert-msg {
  color: #fff !important;
  margin-top: 50px;
  z-index: 1;
}
.common-input {
  border: 1px solid #a6d477;
  line-height: 48px;
  width: 100%;
  display: block;
  background: transparent;
  padding: 0 25px;
  color: #fff;
}
.common-textarea {
  border: 1px solid #a6d477;
  width: 100%;
  display: block;
  background: transparent;
  padding: 15px 25px;
  color: #fff;
  height: 150px;
  resize: none;
}
footer {
  background: #fff;
}
footer .footer-nav li {
  margin-top: 8px;
}
footer .footer-nav li a {
  color: #777;
}
footer .footer-nav li a:hover {
  color: #6cbb23;
}
.single-footer-widget {
  margin-bottom: 30px;
}
.single-footer-widget h6 {
  color: #000;
}
.footer-bottom {
  border-top: 1px solid #eeeeee;
  padding-top: 40px;
}
.footer-bottom .footer-text {
  color: #777;
}
.footer-bottom .footer-text i {
  color: #6cbb23;
}
.footer-bottom .footer-text a {
  color: #6cbb23;
}
.footer-bottom .footer-text a:hover {
  color: #6cbb23;
}
@media (max-width: 767px) {
  .footer-social {
    margin-top: 10px;
  }
}
.footer-social a {
  width: 30px;
  background: #f9f9ff;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.footer-social a i {
  color: #777;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-social a:last-child {
  margin-right: 0;
}
.footer-social a:hover {
  background: #6cbb23;
}
.footer-social a:hover i {
  color: #fff;
}
.generic-header .menu-bar span {
  color: #777;
}
.generic-header nav a {
  color: #000;
}
.generic-banner {
  background: #fff;
  color: #222;
}
.about-generic-area {
  background: #fff;
}
.about-generic-area .border-top-generic {
  padding: 100px 15px;
}
.about-generic-area p {
  margin-bottom: 20px;
}
.white-bg {
  background: #fff;
}
.section-top-border {
  padding: 70px 0;
  border-top: 1px dotted #eee;
}
.switch-wrap {
  margin-bottom: 10px;
}
.switch-wrap p {
  margin: 0;
}
.elements-banner {
  color: #000 !important;
}
